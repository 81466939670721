<script>
import axios from 'axios'
import { BeaconapiUrl } from '../constants/config'
import { mapMutations } from 'vuex'
import { instance } from '../../src/request/app-axios.js'

export default {
  data () {
    return {
      phones: [],
      phoneTypes: [],
      statusCode: null,
      companyPhone: {}
    }
  },

  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setModalDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'setModalInfoMessage',
      'appendInfoMessage',
      'appendDangerMessage'
    ]),
    async getPhonesForCompany (companyID) {
      await axios
        .get(`${BeaconapiUrl}/api/company-phone/${companyID}/table`)
        .then((result) => {
          this.phones = result.data
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async getPhoneTypes () {
      await axios
        .get(`${BeaconapiUrl}/api/company-phone/phone-type`)
        .then((result) => {
          this.phoneTypes = result.data
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async addCompanyPhone (newPhone) {
      newPhone.phoneNumber = this.removePhoneMask(newPhone.phoneNumber)
      await instance
        .post(`${BeaconapiUrl}/api/company-phone`, newPhone)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage('New phone number ' + newPhone.phoneNumber + ' successfully added.')
          console.log('instance', instance, 'newPhone', newPhone)
        })
        .catch((error) => {
          this.setDangerMessage(error)
          throw error
        })
    },
    async updateCompanyPhone (companyPhone) {
      // Removing the mask here b/c if we remove it directly within the page model, v-mask gets confused.
      companyPhone.phoneNumber = this.removePhoneMask(companyPhone.phoneNumber)
      await axios
        .put(`${BeaconapiUrl}/api/company-phone/${companyPhone.companyPhoneID}`, companyPhone)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage('Successfully updated phone number ' + companyPhone.phoneNumber)
          // ideally we shouldn't have to do this. But phoneTypeDesc wont update in the UI if we dont requery the API for it
          this.getPhonesForCompany(companyPhone.companyID)
        })
        .catch((error) => {
          this.setDangerMessage(error)
          throw error
        })
    },
    async deleteCompanyPhone (companyPhoneID) {
      await axios
        .delete(`${BeaconapiUrl}/api/company-phone/${companyPhoneID}`)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
          // remove the deleted record from our local data
          this.phones.splice(this.phones.findIndex(function (phone) {
            return phone.companyPhoneID === companyPhoneID
          }), 1)
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async getCompanyPhoneBycompanyPhoneID (companyPhoneID) {
      await axios
        .get(`${BeaconapiUrl}/api/company-phone/${companyPhoneID}`)
        .then((result) => {
          this.companyPhone = result.data
        })
        .catch((error) => {
          this.setModalDangerMessage(error)
        })
    }
  }
}
</script>
